import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

@Component({
  selector: 'ace-chart-category-item',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  template: `
    <li
      class="group inline-flex h-8 w-32 cursor-pointer items-center justify-start gap-3 rounded-3xl px-4 transition-colors"
      [class]="
        active()
          ? 'bg-primary hover:bg-primary text-neutral-50 hover:bg-opacity-100'
          : 'text-tertiary-600 hover:text-tertiary-900 dark:text-tertiary-300 bg-opacity-0 hover:bg-opacity-5 hover:shadow dark:hover:bg-neutral-50 dark:hover:bg-opacity-5 dark:hover:text-neutral-50'
      "
    >
      <mat-icon
        class="icon-size-4"
        [class]="active() ? 'text-neutral-50' : 'text-tertiary-600 group-hover:text-tertiary-900 dark:text-tertiary-300 dark:group-hover:text-neutral-50'"
        [svgIcon]="'custom:' + icon()"
      ></mat-icon>
      {{ name() }}
    </li>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartCategoryItemComponent {
  name = input.required<string>()
  active = input.required<boolean>()
  // category = input.required<string>()
  icon = input.required<string>()
}
