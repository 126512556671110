<div
  class="dark:bg-tertiary-950 flex select-none flex-col items-end justify-start gap-2 overflow-hidden rounded-lg bg-neutral-50 p-2 shadow"
  [style.width.px]="width()"
  [style.height.px]="height()"
>
  <!-- (valueChange)="valueChange($event)" -->
  <ace-searchbar [(keywords)]="keywords" class="w-full"></ace-searchbar>

  <div class="flex h-full min-h-0 w-full">
    <div class="inline-flex w-[156px] flex-col items-center justify-start overflow-hidden px-2">
      <mat-tab-group class="inline-flex h-full flex-col items-start justify-start" (selectedTabChange)="selectedTabChange($event)">
        <mat-tab label="按类型">
          <ul aceScrollbar class="flex h-full flex-col gap-1 p-2">
            <ace-chart-category-item
              [name]="allCategory[langField()]"
              [active]="activeType() === allCategory"
              [icon]="allCategory.icon"
              (click)="selectType(allCategory)"
            />
            @for (type of types(); track $index) {
              <ace-chart-category-item [name]="type[langField()]" [active]="activeType() === type" [icon]="type.icon" (click)="selectType(type)" />
            }
          </ul>
        </mat-tab>
        <mat-tab label="按功能">
          <ul aceScrollbar class="flex h-full flex-col gap-1 p-2">
            <ace-chart-category-item
              [name]="allCategory[langField()]"
              [active]="activeScenario() === allCategory"
              [icon]="allCategory.icon"
              (click)="selectScenario(allCategory)"
            />
            @for (scenario of scenarios(); track $index) {
              <ace-chart-category-item
                [name]="scenario[langField()]"
                [active]="activeScenario() === scenario"
                [icon]="scenario.icon"
                (click)="selectScenario(scenario)"
              />
            }
          </ul>
        </mat-tab>
      </mat-tab-group>
    </div>

    <ul
      #scrollList
      aceScrollbar
      [aceScrollbarOptions]="{ suppressScrollX: true }"
      class="bg-tertiary-50 flex h-full flex-1 flex-wrap content-start gap-2 rounded-lg p-2 dark:bg-neutral-950"
    >
      @for (chart of results(); track $index) {
        <!-- <li
          class="before:hover:border-primary relative inline-flex h-28 w-40 cursor-pointer flex-col items-start justify-start rounded-lg bg-neutral-50 before:pointer-events-none before:absolute before:-left-0.5 before:-top-0.5 before:box-content before:h-full before:w-full before:rounded-[10px] before:content-[''] hover:shadow before:hover:border-2"
          (click)="clickChart(chart.id)"
        > -->
        <li
          class="relative inline-flex h-28 w-40 cursor-pointer flex-col items-start justify-start rounded-lg bg-neutral-50 hover:shadow"
          (click)="clickChart(chart)"
        >
          <img class="h-22 w-40 rounded-tl-lg rounded-tr-lg" [src]="chart.snapshot" />
          <div class="dark:bg-tertiary-600 inline-flex h-6 w-40 items-center justify-center rounded-bl-lg rounded-br-lg py-1">
            <div class="text-tertiary-900 text-xs leading-3 dark:text-neutral-50">
              {{ chart[langField()] }}
            </div>
          </div>
        </li>
      }
    </ul>
  </div>
</div>
