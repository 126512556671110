import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, OnInit, signal, viewChild } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs'

import _ from 'lodash'

import { AppStore } from '@libs/ng-core/store'
// import { match } from 'pinyin-pro'

import { SearchbarComponent } from '@libs/ng-shared/components/searchbar/searchbar.component'
import { ScrollbarDirective } from '@libs/ng-shared/directives/scrollbar'
import { IChart, IChartCategory } from '@libs/payload'

import { ProjectService } from '../../services'
import { StageUiStore } from '../../store/stage-ui.store'
import { ChartCategoryItemComponent } from './chart-category-item.component'

@Component({
  selector: 'ace-meta-chart-list',
  standalone: true,
  imports: [CommonModule, SearchbarComponent, MatTabsModule, ScrollbarDirective, MatIconModule, ChartCategoryItemComponent],
  templateUrl: './meta-chart-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetaChartListComponent implements OnInit {
  projectService = inject(ProjectService)
  appStore = inject(AppStore)
  uiStore = inject(StageUiStore)

  width = input<number>(684)
  height = input<number>(480)
  closeDialog = input.required<() => void>()
  selectChart = input.required<(chart: IChart) => void>()

  scrollList = viewChild<ElementRef<HTMLElement>>('scrollList')

  charts = this.appStore.metaCharts

  langField = computed(() => {
    return this.appStore.language() === 'en' ? 'enName' : 'name'
  })

  types = computed<IChartCategory[]>(() =>
    _.uniqBy(
      this.charts()
        .map(i => i.types)
        .flat(),
      'name'
    )
  )

  scenarios = computed<IChartCategory[]>(() =>
    _.uniqBy(
      this.charts()
        .map(i => i.scenarios)
        .flat(),
      'name'
    )
  )

  keywords = signal<string>('')

  allCategory: IChartCategory = {
    icon: 'chart-type-all',
    name: '全部',
    enName: 'all'
  }

  activeType = signal<IChartCategory>(this.allCategory)

  activeScenario = signal<IChartCategory>(this.allCategory)

  results = computed<IChart[]>(() =>
    this.charts().filter(
      i =>
        // (!this.keywords() || match(i[this.langField()], this.keywords())) &&
        i[this.langField()].includes(this.keywords()) &&
        (this.activeType() === this.allCategory || i.types.some(j => j.name === this.activeType().name)) &&
        (this.activeScenario() === this.allCategory || i.scenarios.some(j => j.name === this.activeScenario().name))
    )
  )

  constructor() {
    // effect(
    //   () => {
    //     const pages = this.projectService.currentPages()
    //     const onStagePageId = untracked(this.uiStore.onStagePageId)
    //     if (pages.length > 0) {
    //       if (onStagePageId === '') {
    //         this.uiStore.setOnStagePageId(pages[0].id)
    //         this.uiStore.setPageSize(pages[0].size)
    //         this.uiStore.setTheme(pages[0].theme)
    //       } else {
    //         if (!pages.find(page => page.id === onStagePageId)) {
    //           this.uiStore.setOnStagePageId(pages[0].id)
    //           this.uiStore.setPageSize(pages[0].size)
    //           this.uiStore.setTheme(pages[0].theme)
    //         }
    //       }
    //     } else {
    //       this.uiStore.setOnStagePageId('')
    //     }
    //   },
    //   { allowSignalWrites: true }
    // )
  }

  ngOnInit(): void {
    return
  }

  valueChange(event: string) {
    // this.keywords.set(event)
    // this.cdr.detectChanges()
  }

  selectedTabChange(e: MatTabChangeEvent) {
    if (e.index === 0) {
      this.selectType(this.allCategory)
    } else {
      this.selectScenario(this.allCategory)
    }
  }

  selectType(category: IChartCategory) {
    this.activeScenario.set(this.allCategory)
    this.activeType.set(category)

    this.scrollList()?.nativeElement.scrollTo(0, 0)
  }

  selectScenario(func: IChartCategory) {
    this.activeType.set(this.allCategory)
    this.activeScenario.set(func)

    this.scrollList()?.nativeElement.scrollTo(0, 0)
  }

  clickChart(chart: IChart) {
    this.selectChart()(chart)
    this.closeDialog()()
  }
}
